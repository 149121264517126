import React from "react"
import { Spinner as BSSpinner } from 'react-bootstrap'

export const Spinner = (props) => {
  return <BSSpinner animation="border" role="status" {...props}>
    <span className="sr-only">Loading...</span>
  </BSSpinner>
}

export default Spinner
