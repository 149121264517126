import { Link, navigate } from "gatsby"
import React from "react"
import { Button, Nav } from "react-bootstrap"
import firebase from '../config/config'
import SEO from "./seo"

export const PATHS = {
    root: '/',
    intro: '/intro',
    plans: '/pricing',
    logout: '/',
    login: '/app/login',
    dashboard: '/app/dashboard',
    subscriptions: '/app/subscriptions',
    friends: '/app/friends',
    profile: '/app/profile',
}

export const Seo = {
    Root: () => <SEO title="The best way to keep track of your subscriptions" path={PATHS.root} />,
    Intro: () => <SEO title="Why SubClub?" path={PATHS.intro} />,
    Plans: () => <SEO title="Pricing" path={PATHS.plans} />,
    Login: () => <SEO title="Register" path={PATHS.login} />,
    Dashboard: () => <SEO title="Dashboard" path={PATHS.dashboard} />,
    Subscriptions: () => <SEO title="Subscriptions" path={PATHS.subscriptions} />,
    Friends: () => <SEO title="Friends" path={PATHS.friends} />,
    Profile: () => <SEO title="Profile" path={PATHS.profile} />,
    NotFound: () => <SEO title="Page not found" />,
}

export const navTo = {
    root: () => navigate(PATHS.root),
    intro: () => navigate(PATHS.intro),
    plans: () => navigate(PATHS.plans),
    dashboard: () => navigate(PATHS.dashboard),
    subscriptions: () => navigate(PATHS.subscriptions),
    login: () => navigate(PATHS.login),
    logout: () => navigate(PATHS.logout),
    friends: () => navigate(PATHS.friends),
    profile: () => navigate(PATHS.profile),
}

const BaseLink = ({ to, onClick, ...props }) => {
    if (onClick) return <Nav.Link onClick={onClick} {...props} />
    else return <Nav.Link to={to} {...props} as={Link} activeClassName="active" />
}
const BaseButton = ({ className = '', variant = 'primary', size = 'lg', disabled = false, ...props }) => <Button
    className={`${className} flex-grow-1 flex-md-grow-0 ${disabled ? 'disabled' : ''}`}
    as={Link}
    {...{ variant, size, disabled }}
    {...props}
    activeClassName="active"
/>



export const Links = {
    Root: ({ children = 'SubClub', to = PATHS.root, ...props }) => <BaseLink {...{ children, to }} {...props} />,
    Intro: ({ children = 'Why SubClub?', to = PATHS.intro, ...props }) => <BaseLink {...{ children, to }} {...props} />,
    IntroOnClick: ({ children = 'Why SubClub?', ...props }) => <BaseLink {...{ children }} {...props} />,
    Plans: ({ children = 'Pricing', to = PATHS.plans, ...props }) => <BaseLink {...{ children, to }} {...props} />,
    Dashboard: ({ children = 'Dashboard', to = PATHS.dashboard, ...props }) => <BaseLink {...{ children, to }} {...props} />,
    Subscriptions: ({ children = 'Subscriptions', to = PATHS.subscriptions, ...props }) => <BaseLink {...{ children, to }} {...props} />,
    Login: ({ children = 'Log in', to = PATHS.login, ...props }) => <BaseLink {...{ children, to }} {...props} />,
    Logout: ({ children = 'Logout', to = PATHS.logout, ...props }) => <BaseLink {...{ children, to }} {...props} />,
    Friends: ({ children = 'Friends', to = PATHS.friends, ...props }) => <BaseLink {...{ children, to }} {...props} />,
    Profile: ({ children = 'Profile', to = PATHS.profile, ...props }) => <BaseLink {...{ children, to }} {...props} />,
}

export const Buttons = {
    Root: ({ children = 'SubClub', to = PATHS.root, ...props }) => <BaseButton {...{ children, to }} {...props} />,
    Intro: ({ children = 'Why SubClub?', to = PATHS.intro, ...props }) => <BaseButton {...{ children, to }} {...props} />,
    Plans: ({ children = 'Pricing', to = PATHS.plans, ...props }) => <BaseButton {...{ children, to }} {...props} />,
    Dashboard: ({ children = 'Dashboard', to = PATHS.dashboard, ...props }) => <BaseButton {...{ children, to }} {...props} />,
    Subscriptions: ({ children = 'Subscriptions', to = PATHS.subscriptions, ...props }) => <BaseButton {...{ children, to }} {...props} />,
    Login: ({ children = 'Log in', to = PATHS.login, ...props }) => <BaseButton {...{ children, to }} {...props} />,
    Logout: ({ children = 'Logout', to = PATHS.root, ...props }) => <BaseButton {...{ children, to }} {...props} />,
    Friends: ({ children = 'Friends', to = PATHS.friends, ...props }) => <BaseButton {...{ children, to }} {...props} />,
    Profile: ({ children = 'Profile', to = PATHS.profile, ...props }) => <BaseButton {...{ children, to }} {...props} />,
}