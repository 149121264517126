import { TweenMax } from 'gsap'

export const ANIMATION_DURATION_SECS = 0.01

export const slideInFromRightConfig = { x: 50, opacity: 0, duration: ANIMATION_DURATION_SECS, ease: "power3" }
export const slideInFromBottomConfig = { y: 50, opacity: 0, duration: ANIMATION_DURATION_SECS, ease: "power3" }
export const fadeInConfig = { opacity: 1, duration: ANIMATION_DURATION_SECS, ease: "power3" }

export const slideInFromRight = (htmlRefArray, staggerDuration = ANIMATION_DURATION_SECS) => {
    return TweenMax.staggerFrom(htmlRefArray, 1, slideInFromRightConfig, staggerDuration)
}

export const slideInFromBottom = (htmlRefArray, staggerDuration = ANIMATION_DURATION_SECS) => {
    return TweenMax.staggerFrom(htmlRefArray, 1, slideInFromBottomConfig, staggerDuration)
}

/** Must start from opacity: 0!! */
export const fadeIn = (htmlRefArray, staggerDuration = ANIMATION_DURATION_SECS) => {
    return TweenMax.staggerTo(htmlRefArray, 1, fadeInConfig, staggerDuration)
}

export const slideInFromRightRefs = (refArray, staggerDuration = ANIMATION_DURATION_SECS) => {
    const htmlRefArray = refArray.map(r => r.current).filter(r => r !== null)
    return slideInFromRight(htmlRefArray, staggerDuration)
}

export const slideInFromBottomRefs = (refArray, staggerDuration = ANIMATION_DURATION_SECS) => {
    const htmlRefArray = refArray.map(r => r.current).filter(r => r !== null)
    return slideInFromBottom(htmlRefArray, staggerDuration)
}

export const fadeInRefs = (refArray, staggerDuration = ANIMATION_DURATION_SECS) => {
    const htmlRefArray = refArray.map(r => r.current).filter(r => r !== null)
    return fadeIn(htmlRefArray, staggerDuration)
}
