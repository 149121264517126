import { useStaticQuery, graphql } from "gatsby"

export const SiteMeta = ({ children }) => {
    const { site: { siteMetadata: meta = {} } = {} } = useStaticQuery(
        graphql`
      query {
        site {
          siteMetadata {
            company
            title
            description
            author
            url
            image
            fbAppId
          }
        }
      }
    `
    )
    return children(meta)
}