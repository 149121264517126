import React from 'react'
import Sugar from 'sugar'
import { formatLongDate } from './Locale'
const { advance, set, reset } = Sugar.Date

export const GrowCol = ({ className = '', ...props }) => <div className={`${className} GrowCol`} {...props}></div>
export const GrowRow = ({ className = '', ...props }) => <div className={`${className} GrowRow`} {...props}></div>
export const GrowRowCol = ({ className = '', ...props }) => <div className={`${className} GrowRowCol`} {...props}></div>

/** Deprecated - calc in backend services for consistency */
export const calcNextDate = (record) => {
    const { frequency, dotw, dotm, moty } = record
    const now = new Date()
    if (frequency === 'yearly') {
        let tmp = set(now, { date: parseInt(dotm || 0), month: moty }, true)
        if (tmp.getTime() < Date.now()) advance(tmp, { years: 1 })
        return tmp
    }
    else if (frequency === 'monthly') {
        let tmp = set(now, { date: parseInt(dotm || 0) }, true)
        if (tmp.getTime() < Date.now()) advance(tmp, { months: 1 })
        return tmp
    }
    else if (frequency === 'weekly') {
        let tmp = set(now, { date: now.getDate() }, true)
        let delta = dotw - tmp.getDay()
        if (delta < 0) delta += 7
        advance(tmp, { days: delta })
        return tmp
    }
}

export const formatInXDays = (deltaDays) => {
    return <span className={deltaDays > 14 ? 'text-muted' : 'text-danger'}>
        {
            deltaDays === 0 ? 'today'
                : deltaDays === 1 ? 'tomorrow'
                    : deltaDays === -1 ? 'yesterday'
                        : deltaDays < -1 ? `${deltaDays} days ago`
                            : `in ${deltaDays} days`
        }
    </span>
}

/** Deprecated - calc in backend services for consistency */
const calcDiffInDays = (dateA, dateB = new Date()) => {
    const tmpA = reset(dateA, 'date')
    const tmpB = reset(dateB, 'date')
    const deltaDays = parseInt(Math.abs(tmpB.getTime() - tmpA.getTime()) / 86400000)
    return deltaDays
}

export const formatFull = (date) => {
    return <span>{formatLongDate(date)} - {formatInXDays(calcDiffInDays(date))}</span>
}

export const FREQUENCY_OPTIONS = [
    { name: 'Weekly', value: 'weekly' },
    { name: 'Monthly', value: 'monthly' },
    { name: 'Yearly', value: 'yearly' },
]
export const DOTW_OPTIONS = [
    { name: 'Mon', value: 1 },
    { name: 'Tue', value: 2 },
    { name: 'Wed', value: 3 },
    { name: 'Thu', value: 4 },
    { name: 'Fri', value: 5 },
    { name: 'Sat', value: 6 },
    { name: 'Sun', value: 0 },
]
export const MOTY_OPTIONS = [
    { name: 'Jan', value: 0 },
    { name: 'Feb', value: 1 },
    { name: 'Mar', value: 2 },
    { name: 'Apr', value: 3 },
    { name: 'May', value: 4 },
    { name: 'Jun', value: 5 },
    { name: 'Jul', value: 6 },
    { name: 'Aug', value: 7 },
    { name: 'Sep', value: 8 },
    { name: 'Oct', value: 9 },
    { name: 'Nov', value: 10 },
    { name: 'Dec', value: 11 },
]