import React from "react"
import { FaCaretDown, FaCaretUp, FaArrowRight, FaUpload, FaDownload, FaPiggyBank as BankIcon } from 'react-icons/fa'
import styled from 'styled-components'
import WalletSvg from '../images/paper-bills-wallet.svg'
import ShoppingListSvg from '../images/shopping-list.svg'
import CurvedUpArrowSvg from '../images/upward.svg'
import SubClubLogoSvg from '../images/subclub-logo.svg'
import PhoneBlurJpg from '../images/phone-blur.jpg'
import WomanWithPhoneJpg from '../images/woman-with-phone.jpg'

export const SubClubLogo = styled(({ innerRef, ...props }) => <img ref={innerRef} src={SubClubLogoSvg} alt="The subclub logo" {...props} />)`
height: 68px;
`

export const Wallet = styled(({ innerRef, ...props }) => <img ref={innerRef} src={WalletSvg} alt="A wallet" {...props} />)`
max-height: 300px;
max-width: 300px;
transform: rotate(20deg);
`

export const ShoppingList = styled(({ innerRef, ...props }) => <img ref={innerRef} src={ShoppingListSvg} alt="A shopping list" {...props} />)`
max-height: 300px;
max-width: 300px;
`

export const CurvedUpArrow = styled(({ innerRef, ...props }) => <img ref={innerRef} src={CurvedUpArrowSvg} alt="A shopping list" {...props} />)`
object-fit: contain;
width: 100%;
height: auto;
`

export const PhoneBlur = styled(({ innerRef, ...props }) => <img ref={innerRef} src={PhoneBlurJpg} alt="A phone with blurred background" {...props} />)`
object-fit: contain;
width: 100%;
height: auto;
`

export const WomanWithPhone = styled(({ innerRef, ...props }) => <img ref={innerRef} src={WomanWithPhoneJpg} alt="A woman holding a phone" {...props} />)`
object-fit: contain;
width: 100%;
height: auto;
`

const primaryColour = '#623ce4'

export const UpIcon = styled(FaCaretUp)`
color: red;
margin-bottom: 3px;
`
export const DownIcon = styled(FaCaretDown)`
color: green;
margin-bottom: 3px;
`
export const ArrowRight = styled(FaArrowRight)`
color: white;
margin-bottom: 3px;
`
export const UploadIcon = styled(FaUpload)`
color: ${primaryColour};
margin-bottom: 3px;
margin-right: 10px;
`
export const DownloadIcon = styled(FaDownload)`
color: ${primaryColour};
margin-bottom: 3px;
margin-right: 10px;
`
export const ImportIcon = styled(BankIcon)`
color: ${primaryColour};
margin-bottom: 3px;
margin-right: 10px;
`