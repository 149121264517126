import React from "react"
import { GlobalStoreContext } from '../context/GlobalStoreContext'

export const Number = ({ value = 0, ...props }) => {
    return <span className={`${value < 0 ? 'text-danger' : ''} text-nowrap`} {...props}>{value.toLocaleString()}</span>
}
export const Currency = ({ value = 0, ...props }) => {
    const { store: { profile: { language, currency } } } = React.useContext(GlobalStoreContext)
    return <span className={`${value < 0 ? 'text-danger' : ''} text-nowrap`} {...props}>{value.toLocaleString(language, { style: "currency", currency, minimumFractionDigits: 2 })}</span>
}
export const ShortCurrency = ({ value = 0, ...props }) => {
    const { store: { profile: { language, currency } } } = React.useContext(GlobalStoreContext)
    return <span className={`${value < 0 ? 'text-danger' : ''} text-nowrap`} {...props}>{value.toLocaleString(language, { style: "currency", currency, minimumFractionDigits: 0 })}</span>
}

export const SubHeading = ({ innerRef, className = '', children = '', ...props }) => <h4 ref={innerRef} className={`${className} mb-0 text-capitalize`} {...props}>{children}</h4>