/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import PropTypes from "prop-types"
import React from "react"
import { Card, Col, Container, Jumbotron, Nav, Navbar, Row } from "react-bootstrap"
import styled from 'styled-components'
import { Spinner } from '../components/Spinner'
import { GrowCol } from '../components/utils'
import firebase from '../config/config'
import { GlobalStoreContext } from "../context/GlobalStoreContext"
import { SubClubLogo } from "./Images"
import { Buttons, Links, navTo } from './navigation'
import { SiteMeta } from "./SiteMeta"

const scrollToRef = (ref) => {
  console.debug(`scrolling to ${ref.current.offsetTop}`)
  window.scrollTo(0, ref.current.offsetTop)
}

const logout = () => {
  console.debug('logging out...')
  firebase.auth().signOut().then(navTo.logout)
}

const InnerContainer = styled(Container)`margin-bottom: 150px;`

/**
 * 
 * @param {*} authorised (Boolean) - true to indicate this page is for a logged in user (default false)
 */
const Layout = ({ children, linkRefs = {}, showLoading = false, menu = "external" }) => {

  const { store: { isLoggedIn = null, loadingStatus = null } = {} } = React.useContext(GlobalStoreContext)

  const isLoading = showLoading !== false && loadingStatus !== null

  return <GrowCol className="bg-subclub py-3">
    <InnerContainer>
      <Card>
        <Card.Body>
          <Navbar expand="lg" className="p-0 mb-3">
            <Navbar.Brand as={isLoggedIn ? Links.Dashboard : Links.Root} className="p-0">
              <SubClubLogo />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="mr-auto" />

              {menu === "external"
                ? <Nav>
                  {linkRefs.intro ? <Links.IntroOnClick onClick={() => scrollToRef(linkRefs.intro)} /> : <Links.Intro />}
                  <Links.Plans />
                  <Links.Login />
                  <Buttons.Login size="md" className="ml-lg-3">Join Free</Buttons.Login>
                </Nav>
                : <Nav>
                  <Links.Dashboard />
                  <Links.Subscriptions />
                  <Links.Friends />
                  <Links.Profile />
                  <Links.Logout onClick={() => logout()} />
                </Nav>
              }
            </Navbar.Collapse>
          </Navbar>

          {isLoading
            ? <>
              <Jumbotron className="text-muted text-center text-125 d-flex justify-content-center align-items-center">
                <Spinner className="mr-3" />
                {loadingStatus}
              </Jumbotron>
            </>
            : children}

          {/* Footer */}
          <Jumbotron className="bg-white border-top mb-0 text-90 py-4">
            <Row>
              <Col xs={6} md={3}>
                <h6 className="text-uppercase text-90 font-weight-bold my-3">product</h6>
                <Links.Intro className="p-0" />
                <Links.Plans className="p-0" />
                <div><a href="#" onClick={e => e.preventDefault()}>Status</a></div>
              </Col>
              <Col xs={6} md={3}>
                <h6 className="text-uppercase text-90 font-weight-bold my-3">service providers</h6>
                <div><a href="#" onClick={e => e.preventDefault()}>Affiliate Program</a></div>
                <div><a href="#" onClick={e => e.preventDefault()}>Partner Program</a></div>
                <div><a href="#" onClick={e => e.preventDefault()}>API</a></div>
                <div><a href="#" onClick={e => e.preventDefault()}>Training</a></div>
              </Col>
              <Col xs={6} md={3}>
                <h6 className="text-uppercase text-90 font-weight-bold my-3">legal</h6>
                <div><a href="#" onClick={e => e.preventDefault()}>Terms</a></div>
                <div><a href="#" onClick={e => e.preventDefault()}>Disclaimer</a></div>
                <div><a href="#" onClick={e => e.preventDefault()}>Privacy</a></div>
              </Col>
              <Col xs={6} md={3}>
                <h6 className="text-uppercase text-90 font-weight-bold my-3">company</h6>
                <div><a href="#" onClick={e => e.preventDefault()}>About</a></div>
                <div><a href="#" onClick={e => e.preventDefault()}>Contact Us</a></div>
                <div><a href="#" onClick={e => e.preventDefault()}>Careers</a></div>
              </Col>
            </Row>
            <div className="text-muted text-90 mt-5">&copy; {new Date().getFullYear() + ''} <SiteMeta>{meta => meta.company}</SiteMeta></div>
          </Jumbotron>
        </Card.Body>
      </Card>
    </InnerContainer>
  </GrowCol >
}

export const PublicLayout = props => <Layout showLoading={false} menu="external" {...props} />
export const LoginLayout = props => <Layout showLoading={true} menu="external" {...props} />
export const PrivateLayout = props => <Layout showLoading={true} menu="internal" {...props} />

// const Layout = ({children}) => {
//   const data = useStaticQuery(graphql`
//     query SiteTitleQuery {
//       site {
//         siteMetadata {
//           title
//         }
//       }
//     }
//   `)

//   return (
//     <>
//       <Header siteTitle={data.site.siteMetadata.title} />
//       <div
//         style={{
//           margin: `0 auto`,
//           maxWidth: 960,
//           padding: `0px 1.0875rem 1.45rem`,
//           paddingTop: 0,
//         }}
//       >
//         <main>{children}</main>
//         <footer>
//           © {new Date().getFullYear()}, Built with
//           {` `}
//           <a href="https://www.gatsbyjs.org">Gatsby</a>
//         </footer>
//       </div>
//     </>
//   )
// }

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  authorised: PropTypes.bool
}

// export default Layout
